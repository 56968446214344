<template>
  <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2" :class="customClassHeader">
    <b-row>
      <b-col
        cols="1"
        md="1"
        lg="3"
        class="d-flex align-items-center"
      >
        <div
          style="cursor: pointer;"
          @click="leftFunction"
          id="button--header__prev"
        >
          <feather-icon
            size="24"
            class="text-dark font-weight-bolder"
            icon="ArrowLeftIcon"
          />
          <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">{{ leftText }}</span>
        </div>
      </b-col>
      <b-col
        cols="6"
        class="d-flex justify-content-center align-items-center"
      >
        <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
          {{ middleText }}
        </h1>
      </b-col>
      <b-col
        v-if="isRigtColumn"
        cols="3"
        class="d-flex align-items-center justify-content-end text-right"
      >
        <b-spinner v-if="isLoading" variant="primary" class="mr-1" />
        <b-button
          id="button--header__next"
          class="px-3"
          :disabled="isDisabled || isLoading"
          @click.stop="rightFunction"
        >
          {{ rightText }}
        </b-button>
      </b-col>
    </b-row>
  </header>
</template>

<script>
import { BRow, BCol, BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    leftText: {
      type: String,
    },
    middleText: {
      type: String,
    },
    rightText: {
      type: String,
    },
    rightFunction: {
      type: Function,
    },
    leftFunction: {
      type: Function,
    },
    customClassHeader: {
      type: String,
    },
    isRigtColumn: {
      type: Boolean,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
