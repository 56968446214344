<template>
  <div class="section">
    <ModalHeader :custom-class-header="type_component !== 'topup' ? 'bg-white' : 'bg-gray-2'
      " :left-text="'Kembali'" :middle-text="title_header" :left-function="directPage" :is-rigt-column="false" />
    <b-container class="pt-5">
      <b-row class="justify-content-center pt-5" style="height: 100vh"
        :class="type_component !== 'topup' ? 'bg-white' : 'bg-gray-2'">
        <b-col cols="12" md="6">
          <b-row>
            <!-- Pilih Tipe Pelanggan -->
            <b-col v-if="type_component == 'customer'" cols="12" class="custom__form--input">
              <h2 class="text-black fw-bold-800 mb-1">
                {{ userData.merchant.name }}
              </h2>
              <h6 class="text-dark fw-bold-600">
                {{ userData.merchant.alias_name }}
              </h6>
              <hr class="my-2" />
            </b-col>
            <b-col v-if="type_component == 'topup'" cols="12">
              <b-card no-body class="border-12" style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04)">
                <div class="d-flex justify-content-between p-2">
                  <h6 class="mb-0 text-black-2 fw-bold-800 size18">
                    Saldo Deposit
                  </h6>
                  <h6 class="mb-0 text-black-2 fw-bold-800 size18" v-if="
                    $route.path !== '/deposit' ||
                    ($route.path === '/deposit' && customer_id)
                  ">
                    {{ result.wallet_balance || 0 | formatAmount }}
                  </h6>
                </div>
                <hr class="my-0" />
                <div class="custom__form--input p-2">
                  <b-form-group>
                    <label for="v-nominal">Nominal {{ !customer_id ? "Topup" : "Withdraw" }}
                      <span class="text-danger">*</span></label>
                    <money id="v-nominal" v-model="amountTopup" v-bind="money" placeholder="Rp 0.00"
                      class="custom__input border-8 pl-1 w-100" />
                  </b-form-group>
                  <b-form-group class="mt-2" v-if="this.$route.path === '/deposit' && !customer_id">
                    <label for="v-customer">Nama Customer <span class="text-danger">*</span></label>
                    <v-select v-model="customerUuid" label="title" :reduce="(option) => option.label" :options="options"
                      placeholder="Pilih Customer" class="text-dark fw-bold-700" @search="onSearch" />
                  </b-form-group>
                  <div class="mt-2">
                    <b-button class="btn-block border-8 py-1" @click="submit">
                      {{ !customer_id ? "Topup" : "Withdraw" }}
                    </b-button>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Email -->
            <template v-if="$route.path !== '/deposit' || customer_id">
              <b-col cols="12" md="6" class="mb-2">
                <label class="text-dark text-darken-4 fw-bold-500 size14">
                  Nama
                </label>
                <h6 class="text-dark size18 fw-bold-600">
                  {{ result.name || "-" }}
                </h6>
              </b-col>
              <!-- No. Telepon -->
              <b-col cols="12" md="6" class="mb-2">
                <label class="text-dark text-darken-4 fw-bold-500 size14">
                  No. Telepon
                </label>
                <h6 class="text-dark size18 fw-bold-600">
                  {{ result.phone_number || "-" }}
                </h6>
              </b-col>
              <!-- Tipe Pelanggan -->
              <b-col cols="12" md="6" class="mb-2">
                <label class="text-dark text-darken-4 fw-bold-500 size14">
                  Tipe Pelanggan
                </label>
                <h6 class="text-dark size18 fw-bold-600">
                  {{ result.customer_type || "-" }}
                </h6>
              </b-col>
              <!-- Status -->
              <b-col cols="12" md="6" class="mb-2">
                <label class="text-dark text-darken-4 fw-bold-500 size14">
                  Status
                </label>
                <h6 class="text-dark size18 fw-bold-600">
                  {{ result.is_active == true ? "Aktif" : "Tidak Aktif" }}
                </h6>
              </b-col>
              <!-- Pembayaran -->
              <!-- <b-col
              cols="12"
              md="12"
              class="mb-2"
            >
              <label class="text-dark text-darken-4 fw-bold-500 size14">
                Pembayaran
              </label>
              <h6 class="text-dark size18 fw-bold-600">
                {{ result.terms_of_payment || '-' }}
              </h6>
            </b-col> -->
              <!-- Alamat -->
              <b-col cols="12" md="12" class="mb-2">
                <label class="text-dark text-darken-4 fw-bold-500 size14">
                  Alamat
                </label>
                <h6 class="text-dark size18 fw-bold-600" v-html="result.address"></h6>
              </b-col>
              <!-- No. NPWP -->
              <!-- <b-col
              cols="12"
              md="12"
              class="mb-2"
            >
              <div class="d-flex align-items-center">
                <div>
                  <label class="text-dark text-darken-4 fw-bold-500 size14">
                    No. NPWP
                  </label>
                  <h6 class="text-dark size18 fw-bold-600">
                    {{ result.tax_id_number || '-' }}
                  </h6>
                </div>
                <div class="ml-2" v-if="result.tax_id_photo_url">
                  <b-button
                    class="bg-white text-dark fw-bold-800 size14"
                    target="_blank"
                    :href="result.tax_id_photo_url"
                  >
                    Lihat Foto NPWP
                  </b-button>
                </div>
              </div>
            </b-col> -->
              <!-- No. KTP -->
              <!-- <b-col
              cols="12"
              md="12"
              class="mb-2"
            >
              <label class="text-dark text-darken-4 fw-bold-500 size14">
                No. KTP
              </label>
              <h6 class="text-dark size18 fw-bold-600">
                {{ result.id_card_number || '-' }}
              </h6>
            </b-col> -->
            </template>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <ModalPayment :getData="getData" />
  </div>
</template>

<script>
import _ from "lodash";
import {
  BContainer,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
  // BFormGroup, BFormInput, BFormTextarea,
} from "bootstrap-vue";
import ModalHeader from "@/components/ModalHeader.vue";
import ModalPayment from "@/components/Deposit/Modal/ModalPayment.vue";
import vSelect from "vue-select";

// import UploadPhoto from '@/components/UploadPhoto.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormGroup,
    BFormInput,
    ModalHeader,
    ModalPayment,
    // BFormTextarea,
    // UploadPhoto,
    vSelect,
  },
  props: {
    /* eslint-disable vue/prop-name-casing */
    type_component: {
      type: String,
      default: "customer",
    },
    title_header: {
      type: String,
      default: "Detail Info",
    },
    results: {
      type: Object,
    },
    getData: {
      type: Function,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      money: {
        decimal: "",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
      result: this.$store.state.customer.result,
      amountTopup: 0,
      customerUuid: "",
      options: [],
      customer_id: localStorage.customer_value,
    };
  },
  watch: {
    results: {
      handler(value) {
        this.results = value;
      },
      deep: true,
    },
    amountTopup(value) {
      this.$store.commit("customer/setFormPayloadTopup", { amount: value });
    },
    customerUuid(value) {
      this.$store.commit("customer/setCustomer", value);
    },
  },
  mounted() {
    if (this.type_component == "topup" || this.type_component == "piutang") {
      this.result = this.results;
    }

    if (this.$route.path === "/deposit") {
      this.getCustomer();
    }
  },
  methods: {
    submit() {
      this.$bvModal.show("modal-payment");
    },
    directPage() {
      switch (this.type_component) {
        case "customer":
          this.$store.commit("customer/setResult", {});
          this.$bvModal.hide("modal-customer");
          break;
        case "topup":
          localStorage.removeItem("customer_value");
          this.$bvModal.hide("modal-deposit");
          break;
        default:
          this.$bvModal.hide("modal-customer");
          break;
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCustomer(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.options = [];
          this.getCustomer();
          loading(false);
        }, 500);
      }
    },
    searchCustomer: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("customer/getData", {
          uuid: "",
          params: {
            search: search,
          },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          vm.options = [];
          response.data.data.data.forEach((item) => {
            vm.options.push({
              title: item.name,
              label: item.uuid,
            });
          });
          loading(false);
        });
    }, 300),
    getCustomer() {
      this.$store
        .dispatch("customer/getData", {
          uuid: "",
          params: {
            per_page: 15,
          },
        })
        .then((result) => {
          for (let index = 0; index < result.data.data.data.length; index++) {
            const element = result.data.data.data[index];
            this.options.push({
              title: element.name,
              label: element.uuid,
            });
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.bg-white.btn-secondary:focus {
  background-color: transparent !important;
}

.btn-type {
  background-color: #eff1f5 !important;
  border: 1px solid #e4e5ec !important;
  border-radius: 8px;
  margin-right: 15px;
  color: $dark !important;

  &.active {
    background-color: #ffffff !important;
    border: 1px solid #89a7e0 !important;
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04);
    color: #2e5aac !important;
  }
}
</style>
